















import { Component, Prop, Vue } from "vue-property-decorator";

import CustomDataRequestForm from "@/settings/components/networks-management/CustomDataRequestForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetworkUrlResponseModel from "@/settings/models/networks-management/NetworkUrlResponseModel";
import { RequestType } from "@/settings/models/networks-management/NetworkUrlRequestModel";

@Component({
  components: {
    CustomDataRequestForm,
  },
})
export default class CustomDataRequestsView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  get customDataRequests(): Array<NetworkUrlResponseModel> {
    return this.$store.state.networksManagementStore.networkRequests.filter(
      ({ requestType }: { requestType: RequestType }) =>
        ![
          RequestType.DATA_FETCH,
          RequestType.AUTH,
          RequestType.PERSISTENT_AUTH,
        ].includes(requestType)
    );
  }

  destroyed() {
    this.$store.commit("clearRequestParams");
  }
}
