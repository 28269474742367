



























































































































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";

import EntityCard from "./EntityCard.vue";
import RequestParamsTable from "./RequestParamsTable.vue";
import RequestSpecialParamsTable from "./RequestSpecialParamsTable.vue";
import NetworkUrlRequestModel, {
  HttpMethod,
  NetworkUrlPaginationModel,
  PaginationType,
} from "@/settings/models/networks-management/NetworkUrlRequestModel";
import NetworkUrlResponseModel from "@/settings/models/networks-management/NetworkUrlResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    EntityCard,
    RequestParamsTable,
    RequestSpecialParamsTable,
  },
})
export default class CustomDataRequestForm extends Vue {
  @Prop() customDataRequest!: NetworkUrlResponseModel;
  @Prop() networkIntegrationId!: number;
  @Prop() hasSpecialParams!: boolean;

  @Ref("urlForm") readonly urlForm!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly httpMethodItems = Object.entries(HttpMethod).map(([key, value]) => ({
    text: key,
    value,
  }));
  readonly paginationTypeItems = Object.entries(PaginationType).map(
    ([key, value]) => ({
      text: this.$lang(`appAdmin.networksManagement.${key.toLowerCase()}`),
      value,
    })
  );

  isUrlFormValid = true;
  isEditableNetworkRequest = false;
  localValue = new NetworkUrlRequestModel();

  get networkRequestsIsLoading(): boolean {
    return this.$store.state.networksManagementStore.networkRequestsIsLoading;
  }

  get savingNetworkRequestInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingNetworkRequestInProgress;
  }

  get networkRequests(): Array<NetworkUrlResponseModel> {
    return this.$store.state.networksManagementStore.networkRequests;
  }

  get localValuePagination(): NetworkUrlPaginationModel {
    return this.localValue.pagination;
  }

  get localValuePaginationType(): PaginationType | undefined {
    return this.localValue.pagination?.type || undefined;
  }

  get isEndDateVisible(): boolean {
    return (
      !!this.localValuePaginationType &&
      [
        PaginationType.DATE_INTERVAL,
        PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
        PaginationType.DATE_INTERVAL_WITH_OFFSET,
        PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
      ].includes(this.localValuePaginationType)
    );
  }

  get isNextKeyVisible(): boolean {
    return (
      !!this.localValuePaginationType &&
      [
        PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
        PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
      ].includes(this.localValuePaginationType)
    );
  }

  get isMinContentLengthVisible(): boolean {
    return (
      !!this.localValuePaginationType &&
      [
        PaginationType.OFFSET,
        PaginationType.DATE_INTERVAL_WITH_OFFSET,
      ].includes(this.localValuePaginationType)
    );
  }

  get isLengthPageVisible(): boolean {
    return (
      this.localValuePaginationType === PaginationType.DATE_INTERVAL_WITH_OFFSET
    );
  }

  @Watch("customDataRequest", { immediate: true, deep: true })
  watchCustomDataRequest(value: NetworkUrlResponseModel) {
    this.localValue = NetworkUrlRequestModel.ofSource(value);
  }

  async created() {
    await this.$store.dispatch("loadRequestParamsByRequestId", {
      id: this.customDataRequest.id,
      isCustomDataRequests: true,
    });

    if (this.hasSpecialParams) {
      await this.$store.dispatch(
        "loadRequestSpecialParamsByRequestId",
        this.customDataRequest.id
      );
    }
  }

  handleCancelEditing() {
    this.localValue = NetworkUrlRequestModel.ofSource(this.customDataRequest);
    this.isEditableNetworkRequest = false;
  }

  async handleUpdateNetworkRequest() {
    if (!this.urlForm.validate()) {
      return;
    }

    await this.$store.dispatch("updateNetworkRequest", {
      payload: this.localValue,
      id: this.customDataRequest.id,
    });

    this.isEditableNetworkRequest = false;
  }
}
